import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import QRCode from "react-qr-code";
import { getNftMetadata } from '../lib/blockchain';
import { motion, AnimatePresence } from "framer-motion";

export default function Home(props){    
    
    var navigate = useNavigate();
    const [url, setUrl] = useState('');
    const [md, setMd] = useState(null);

    const fetchNftMetadata = async (contractAddress, tokenId) => {
        var metadata = await getNftMetadata(contractAddress, tokenId);
        console.log(metadata);
        setMd(metadata);
    }

    useEffect(() => {
        // get property from locastorage
        var state = window.localStorage.getItem('IYKYK_STATE');
        if(!state){
            toast.error('Please link your NFT first!');
            navigate('/config');
        }
        var nft = JSON.parse(state);
        setUrl(process.env.REACT_APP_DAPP_URL + '/buy/' + nft.contractAddress + '/' + nft.tokenId);
        fetchNftMetadata(nft.contractAddress, nft.tokenId);
        

    }, []);

    if(url == '') return <div></div>;

    return <div>
            <div className="h-screen flex items-center justify-center">
                <AnimatePresence>
                    <motion.div
                    key={"qrcode"}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}>
                        <div className="flex max-w-4xl mx-auto">
                            <div>
                                <div className="rounded overflow-hidden aspect-square">
                                    <QRCode value={url} />
                                </div>
                            </div>
                            {!!md &&
                                <div className="pl-8">
                                    <div><h2 className="text-2xl font-medium">{md.name}</h2></div>
                                    <div className="mt-3"><h2>{md.description}</h2></div>
                                    
                                    <div className="mt-8 grid grid-cols-2 gap-2" style={{width: 300}}>
                                        {md.attributes.map((attr, i) =>
                                            <>
                                                <div className="font-bold">{attr.trait_type}:</div>
                                                <div>{attr.value}</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </motion.div>
                </AnimatePresence>
                        
            </div>
        </div>;    

}