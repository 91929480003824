import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import Logo from '../images/logo-white.png';
import { getListingsFromMarketplace, getNFTHistory, describeTx, formatAddress } from '../lib/blockchain';
import Chart from '../components/Charts'
import { formatDistanceToNow } from 'date-fns';

export default function Track(props){    
    
    const [listings,] = useGlobal('listings');
    const [readyHistory, setReadyHistory] = useState(false);
    const [history, setHistory] = useState(null);
    
    const fetchData = async () => {
        await getListingsFromMarketplace();
    }

    const fetchHistory = async () => {

        console.log(listings);
        
        // for each nft get the history
        var h = {};
        for(var i in listings){
            h[i] = await getNFTHistory(listings[i].contractAddress, listings[i].tokenId.toNumber(), listings[i].isERC1155);
        }

        setHistory(h);
        setReadyHistory(true);
        
    }

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        if(!listings || listings == []) return;
        fetchHistory();
    }, [listings]);


    if(!readyHistory) {
        return <div>
            <div>Loading...</div>
        </div>;
    }

    console.log(history);


    return <div className="p-12">
            
            <div className="mb-12">
                <img src={Logo} style={{width: 200}} />
            </div>

            {listings.map((listing, index) => 
                <div key={index} className="mb-8 track-nft-timeline flex items-center bg-gray-800 rounded-xl">
                        
                    <div className="track-nft-card">
                        <div className="mb-2 nft-image">
                            <img src={listing.imageUrl} />
                        </div>
                        <h2>{listing.metadata.name}</h2>
                    </div>
                    
                    {history[index] && history[index].map((entry, i) => 
                        <div className="track-nft-card flex items-center" key={i}>
                            <div>
                                <div className="mb-2 font-bold">Block: {entry.blockNum}</div>
                                <div>{ formatDistanceToNow(new Date(entry.metadata.blockTimestamp)) } ago</div>
                                <div className="text-zinc-400">{describeTx(entry)}</div>
                            </div>
                        </div>
                    )}

                    

                </div>

                
            )}

        </div>;    

}