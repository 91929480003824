import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useGlobal } from 'reactn';
import { formatAddress, getListingsFromMarketplace } from '../lib/blockchain';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';

const contractsWithVideos = [
    '0xD3B1ee664eEDc48C149C9fBd9932CAe0e79Cd968',
];

export default function Config(props){    

    var navigate = useNavigate();

    const [listings,] = useGlobal('listings');
    const [readyListings,] = useGlobal('readyListings');

    useEffect(() => {
        getListingsFromMarketplace();
    }, []);

    console.log(listings);

    if(!readyListings) {
        return <div>
            <div>Loading...</div>
        </div>;
    }

    const linkNft = async (listing) => {
        listing.tokenId = listing.tokenId.toNumber();
        window.localStorage.setItem('IYKYK_STATE', JSON.stringify(listing));
        toast.success('NFT was linked successfully!');
        navigate('/');
    }

    
    
    return (<div className="p-5">
        <h1 className="text-xl font-bold">Configuration</h1>
        <div className="text-sm mt-2">Please select which NFT you would like to link</div>
        <table className="config-table mt-12 text-sm">
            <tbody>
            {listings.map((listing, index) => <tr key={index}>
                    <td>
                        <div style={{fontFamily: 'monospace'}}>0x{formatAddress(listing.contractAddress, 8)}</div>
                        <div className="flex items-center">
                            <div className="mr-4">ID: {listing.tokenId.toNumber()}</div>
                            <div className="mr-4">Price: {ethers.utils.formatEther(listing.price)} ETH</div>
                        </div>
                    </td>
                    <td>
                        <a href="#" onClick={e => linkNft(listing)}>

                            {contractsWithVideos.includes(listing.contractAddress) &&
                                <video src={listing.imageUrl} className="config-video w-16 rounded-xl" autoPlay loop muted playsInline />
                            }
                        
                            {!contractsWithVideos.includes(listing.contractAddress) &&
                                <img src={listing.imageUrl} className="config-image w-16 rounded-xl" />
                            }
                                
                        </a>
                    </td>
                    <td>
                    <div><a href="#" className="button" onClick={e => linkNft(listing)}>Select</a></div>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    </div>);    
}