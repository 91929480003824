import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Buy from "./pages/Buy";
import Config from "./pages/Config";
import Home from "./pages/Home";
import Track from "./pages/Track";
import "./style.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, goerli, sepolia } from 'wagmi/chains';
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from 'wagmi/connectors/injected';


const { chains, provider } = configureChains([ process.env.REACT_APP_NETWORK == 1 ? mainnet : (process.env.REACT_APP_NETWORK == 5 ? goerli : sepolia) ],
    [infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID }), publicProvider()]
);


// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: [new InjectedConnector({ chains })],
//   provider,
// })
  
const { connectors } = getDefaultWallets({
    appName: "iykyk exhibition",
    chains
});
  
const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
});

window.Buffer = window.Buffer || require("buffer").Buffer;
  
function App() {

    return (
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
    
                <div className="App">
                    <Toaster />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/config" element={<Config />} />
                            <Route path="/buy/:contractAddress/:tokenId" element={<Buy action="buy" />} />
                            <Route path="/mint" element={<Buy action="mint" />} />
                            <Route path="/track" element={<Track />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </RainbowKitProvider>
        </WagmiConfig>);
}

export default App;
