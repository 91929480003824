import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { useNavigate, useParams } from 'react-router-dom';
import { buyNFT, mintNFT, mintRabbitHole, getNftImage, getNftPrice } from '../lib/blockchain';

import { ConnectButton } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { useAccount, useSigner } from "wagmi";

export default function Buy(props){    
    
    var navigate = useNavigate();
    
    let { contractAddress, tokenId } = useParams();
    const { address, isConnected } = useAccount();
    const { data: signerData } = useSigner();
    const [nftImage, setNftImage] = useState(null);
    const [price, setPrice] = useState(null);
    const [mintedOK] = useGlobal('mintedOK');

    // these parameters are passed in case of minting
    let urlParams = new URLSearchParams(window.location.search);
    var mintContract = urlParams.get('contract'); // passed always
    var mintHash = urlParams.get('hash'); // only for rabbit hole
    var mintCid = urlParams.get('cid'); // only for rabbit hole

    
    // set globals to use from blockchain.js
    if(address && isConnected && signerData) {
        window.signer = signerData;
        window.wallet = address;
        window.provider = signerData.provider;
    }

    const buy = () => {
        buyNFT(contractAddress, tokenId);
    }

    const mint = () => {

        if(mintContract && mintContract.toLowerCase() == process.env.REACT_APP_RABBIT_HOLE_CONTRACT.toLowerCase()){
            mintRabbitHole(mintHash, mintCid);
        }else{
            mintNFT(mintContract);
        }
        
    }

    const loadNftImage = async () => {

          if(!contractAddress || !tokenId) return;

          let image = await getNftImage(contractAddress, tokenId);
          setNftImage(image);

          let p = await getNftPrice(contractAddress, tokenId);
          console.log('price: ', p);
          setPrice(p);


    }

    useEffect(() => {
        loadNftImage();
    }, []);


    
    return <div className="App">

    

        <div className="p-5">

                <div>
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 950 350" fill="white" height="50" style={{margin: '25px auto 30px auto'}}>
                      <g>
                        <path d="M62.5,261.2l24.3-138h30.9l-24.3,138H62.5z M93.2,100.4c-3-3.7-4-8.2-3.1-13.4c0.9-5.2,3.5-9.6,7.8-13.2
                          c4.3-3.6,9-5.4,14.2-5.4c5.2,0,9.3,1.8,12.4,5.4c3.1,3.6,4.2,8,3.2,13.2c-0.9,5.2-3.6,9.7-7.9,13.4c-4.4,3.7-9.2,5.6-14.4,5.6
                          C100.3,105.9,96.2,104.1,93.2,100.4z"/>
                        <path d="M131.9,302.2l5.1-29c8.2,0,14.7-1.5,19.6-4.5c4.9-3,9.7-8.1,14.3-15.3l3.8-5.6L137,123.2h31.8l19.5,74.2l5.7,21.5
                          l13.4-23.1l42.2-72.6h31.2l-88.4,144.1C178.1,290.6,158,302.2,131.9,302.2z"/>
                        <path d="M276.1,261.2l32.3-183.5h30.9l-19.5,110.4l65.8-64.9h38.2l-65,62l50.7,76H372l-35.6-54.5l-23.8,22.7l-5.6,31.8H276.1z"/>
                        <path d="M432.2,302.2l5.1-29c8.2,0,14.7-1.5,19.6-4.5c4.9-3,9.7-8.1,14.3-15.3l3.8-5.6l-37.7-124.6h31.8l19.5,74.2l5.7,21.5
                          l13.4-23.1l42.2-72.6h31.2l-88.4,144.1C478.4,290.6,458.2,302.2,432.2,302.2z"/>
                        <path d="M576.3,261.2l32.3-183.5h30.9l-19.5,110.4l65.8-64.9h38.2l-65,62l50.7,76h-37.6l-35.6-54.5l-23.8,22.7l-5.6,31.8H576.3z"/>
                        <path d="M796.6,172.1l8.7-49.4h-50.2l4.4-25.1h50.2l8.8-49.9h27.3l-8.8,49.9h50.5l-4.4,25.1h-50.5l-8.7,49.4H796.6z"/>
                      </g>
                  </svg>
              </div>


              <div className={"thank-you-screen max-w-md mx-auto text-center " + (!mintedOK ? 'hidden' : '')}>
                  <h2 className="text-white text-3xl font-bold mt-4 mb-6">Congratulations!</h2>
                  <div className="mb-8">You have successfully minted your NFT.</div>
                  <div>
                      <img src="/rabbit.png" style={{width: 90, margin: '0 auto'}} />
                  </div>
              </div>

              <div className={"buy-screen " + (mintedOK ? 'hidden' : '')}>
                  <div className="connect-container">
                      <ConnectButton style={{justifyContent: 'center'}} />
                  </div>

                  <div>
                      {props.action == 'mint' && mintContract.toLowerCase() == process.env.REACT_APP_BITCOIN_LIVES_CONTRACT.toLowerCase() &&
                        <div style={{borderRadius: 10, overflow: 'hidden', margin: '0 auto 20px auto', width: 200, height: 200, position: 'relative'}}>
                            <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" height="200" width="200" sandbox="allow-scripts" src="https://ipfs.io/ipfs/QmS8GeANmX9vEr7DNdH97To1krViaH76sfJ7QqLz7kCywo"></iframe>
                        </div>
                      }
                      {props.action == 'buy' && nftImage &&
                          <div style={{position: 'relative', width: 200, height: 200, overflow: 'hidden', borderRadius: 10, margin: '0 auto', marginBottom: 20, backgroundColor: '#29303d'}}>
                              <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                              {contractAddress.toLowerCase() != process.env.REACT_APP_PASSES_CONTRACT.toLowerCase() &&
                                  <img src={nftImage} style={{position: 'relative', zIndex: 1, width: '200px', borderRadius: '10px'}} />
                              }
                              {contractAddress == process.env.REACT_APP_PASSES_CONTRACT &&
                                  <video src={nftImage} style={{position: 'relative', zIndex: 1, width: '200px', borderRadius: '10px'}} autoPlay loop muted playsInline />
                              }
                          </div>
                      }
                  </div>

                  {address && isConnected && signerData &&
                      <div className="text-center">
                        
                        {props.action == 'buy' && price &&
                          <button onClick={() => buy()} className="button">Buy this NFT for Ξ {price}</button>
                        }
                        
                        {props.action == 'mint' &&
                          <button onClick={() => mint()} className="button">Mint NFT for Ξ {process.env.REACT_APP_RABBITHOLE_PRICE}</button>
                        }

                      </div>
                  }
            </div>
        </div>
    </div>;    
}