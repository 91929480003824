import { ethers } from 'ethers';
import { setGlobal } from 'reactn';
import abiMarketplace from './abiMarketplace.json';
import abi721 from './abi721.json';
import abi1155 from './abi1155.json';
import abiRabbitHole from './abiRabbitHole.json';
import toast from 'react-hot-toast';
import { formatDistanceToNow } from 'date-fns';

console.log('.env infura name:', process.env.REACT_APP_NETWORK_INFURA_NAME);
console.log('.env infura id:', process.env.REACT_APP_INFURA_ID);

// init Infura
const provider = new ethers.providers.InfuraProvider(process.env.REACT_APP_NETWORK_INFURA_NAME, process.env.REACT_APP_INFURA_ID);
window.infuraProvider = provider;

var currentToast = '';
var currentToastId = null;

export async function getNFTHistory(contractAddress, tokenId, is1155){
    var data = await fetch(process.env.REACT_APP_BACKEND_URI + '/track-token?contractAddress=' + contractAddress + '&tokenId=' + tokenId + '&erc1155=' + (is1155 ? 'true' : 'false'));
    var txlist = await data.json();
    if(is1155){
        var uniqueId = null;
        var filtered = [];
        for(var tx of txlist){
            if(tx.to.toLowerCase() == process.env.REACT_APP_MARKETPLACE_CONTRACT.toLowerCase()){
                uniqueId = tx.uniqueId;
            }
        }
        for(var tx of txlist){
            if(tx.uniqueId == uniqueId){
                filtered.push(tx);
            }
        }
        txlist = filtered;
    }
    console.log(txlist);
    return txlist;
}


export async function getBlockDate(blockNumber){
    var block = await window.infuraProvider.getBlock(blockNumber);
    console.log('timestamp:');
    console.log(block.timestamp); 
    console.log('date from timestamp:');
    console.log(new Date(block.timestamp * 1000));
    return formatDistanceToNow(new Date(block.timestamp * 1000));
}


export function showLoadingToast(msg){
    if(currentToast != msg){
        toast.dismiss(currentToastId);
        currentToast = msg;
        currentToastId = toast.loading(msg);
    }
}

export function hideLoadingToast(){
    currentToast = '';
    toast.dismiss(currentToastId);
}


export async function readContract(contract, abi, func, args = []){

    console.log('calling static contract function: ' + func + ' on contract: ' + contract + ' with args: ', args);
    
    var contract = new ethers.Contract(contract, abi, window.infuraProvider);

    // determine if function is a transaction or constant
    var functions = contract.interface.functions;
    var constant = false;
    for (var key in functions) {
        if(functions[key].name == func){
            constant = functions[key].constant;
        }
    }

    if(constant){
        var result = await contract[func](...args);
        return result;
    }else{
        console.log('function is not constant');
        return;
    }
}

export function ipfsGateway(url){
    url = url.replace('ipfs://', process.env.REACT_APP_IPFS_GATEWAY + '/');
    return url;
}

export async function getNftMetadata(contractAddress, tokenId){
    // check if the contract is ERC1155 or ERC721
    var isERC1155 = await readContract(contractAddress, abi1155, 'supportsInterface', ['0xd9b67a26']);
    console.log('is erc1155: ' + isERC1155);
    if(isERC1155){
        var metadataUrl = await readContract(contractAddress, abi1155, 'uri', [tokenId]);
    }else{
        var metadataUrl = await readContract(contractAddress, abi721, 'tokenURI', [tokenId]);
    }
    var metadata = await fetch(ipfsGateway(metadataUrl)).then(response => response.json());
    return metadata;
}

export async function getListingsFromMarketplace(){
    const listings = await readContract(process.env.REACT_APP_MARKETPLACE_CONTRACT, abiMarketplace, 'getListings');
    
    var resolvedListings = [];
    for(var listing of listings){
        
        // get metadata
        if(listing.isERC1155){
            var metadataUrl = await readContract(listing.contractAddress, abi1155, 'uri', [listing.tokenId]);
        }else{
            var metadataUrl = await readContract(listing.contractAddress, abi721, 'tokenURI', [listing.tokenId]);
        }

      
        if(metadataUrl.includes('data:application/json,')){
          var metadata = JSON.parse(decodeURIComponent(metadataUrl.replace('data:application/json,', '')));
        }else{
          var metadata = await fetch(ipfsGateway(metadataUrl)).then(response => response.json());
        }

        // get image
        var imageUrl = ipfsGateway(metadata.image);

        resolvedListings.push({
            metadata,
            imageUrl,
            ...listing
        });

    }

    setGlobal({ listings: resolvedListings, readyListings: true });

    return resolvedListings;

}

export async function getNftPrice(contractAddress, tokenId){
    var listings = await getListingsFromMarketplace();
    var price = 0;
    for(var listing of listings){
        if(tokenId == 'any' && listing.contractAddress.toLowerCase() == contractAddress.toLowerCase()){
            price = listing.price.toString();
        }
        if(tokenId != 'any' && listing.tokenId.toNumber() == tokenId && listing.contractAddress.toLowerCase() == contractAddress.toLowerCase()){
            price = listing.price.toString();
        }
    }
    return ethers.utils.formatEther(price);
}


export async function getNftImage(contract, tokenId){
    if(tokenId == 'any') tokenId = 1;
    var metadataUrl = null;
    try{
      metadataUrl = await readContract(contract, abi1155, 'uri', [tokenId]);
    }catch(e){
      console.log('not erc1155');
    }
    if(!metadataUrl){
      metadataUrl = await readContract(contract, abi721, 'tokenURI', [tokenId]);
    }
    

    if(metadataUrl.includes('data:application/json,')){
      var metadata = JSON.parse(decodeURIComponent(metadataUrl.replace('data:application/json,', '')));
    }else{
      var metadata = await fetch(ipfsGateway(metadataUrl)).then(response => response.json());
    }

    // get image
    var imageUrl = ipfsGateway(metadata.image);

    return imageUrl;

}


export async function runContract(contractAddress, abi, func, args = [], event = '', eventCallback = () => {}, ethValue = null){

    var signer = window.signer;
    console.log('calling contract function: ' + func + ' on contract: ' + contractAddress + ' with args: ', args);
    console.log('with eth value:', ethValue);

    if(!signer){
        console.warn('trying to call contract function without a signer');
        return;
    }
    
    var contract = new ethers.Contract(contractAddress, abi, window.provider);
    var contractForEvent = new ethers.Contract(contractAddress, abi, window.infuraProvider);

    // determine if function is a transaction or constant
    var functions = contract.interface.functions;
    var constant = false;
    for (var key in functions) {
        if(functions[key].name == func){
            constant = functions[key].constant;
        }
    }
    
    var contractWithSigner = contract.connect(signer);

    var ethParams = {};
    
    ethParams = {
        from: window.wallet,
        // gasLimit: 500000,
        // gasPrice: window.provider.getGasPrice()
    };

    if(ethValue) ethParams.value = ethValue;

    try{

        if(event != ''){

            // we will wait for the event to be triggered
            
            contractForEvent.removeAllListeners();
            contractForEvent.once(event, eventCallback);

            showLoadingToast('waiting for transaction...');

            await contractWithSigner[func](...args, ethParams);
            
            return true;

        }else{


            var tx = await contractWithSigner[func](...args, ethParams);
            if(tx.wait){

                // its a blockchain transaction, we have to wait until its mined
                showLoadingToast('waiting for transaction...');
                tx = await tx.wait();    
                hideLoadingToast();
                return true;

            }else{

                // its a read only call
                return tx;

            }   

        }
        
    }catch(e){

        hideLoadingToast();
        showError(e);
        return false;

    }
}

export async function mintRabbitHole(hash, cid){
  
    await runContract(process.env.REACT_APP_RABBIT_HOLE_CONTRACT, abiRabbitHole, 'mint', [hash, cid], 'Minted', (sender) => {
        if(sender != window.wallet) return;
        hideLoadingToast();
        toastSuccess('NFT minted successfully!');
        setGlobal({ mintedOK: true });
        setTimeout(() => {
            setGlobal({ mintedOK: false });
        }, 8000);
    }, ethers.utils.parseEther(process.env.REACT_APP_RABBITHOLE_PRICE));
}

export async function mintNFT(contract){

    var priceInWei = await readContract(contract, abi721, 'getPricePublic');
    await runContract(contract, abi721, 'mint', [], 'Minted', (sender) => {
        if(sender != window.wallet) return;
        hideLoadingToast();
        toastSuccess('NFT minted successfully!');
        setGlobal({ mintedOK: true });
        setTimeout(() => {
            setGlobal({ mintedOK: false });
        }, 8000);
    }, priceInWei);

}

export async function buyNFT(contractAddress, tokenId){
    console.log('token id:', tokenId);
    

    var listings = await getListingsFromMarketplace();

    // find price
    var price = 0;
    console.log(listings);
    
    for(var listing of listings){
        if(tokenId == 'any' && listing.contractAddress.toLowerCase() == contractAddress.toLowerCase()){
            tokenId = listing.tokenId.toNumber();
            price = listing.price.toString();
        }
        if(tokenId != 'any' && listing.tokenId.toNumber() == tokenId && listing.contractAddress.toLowerCase() == contractAddress.toLowerCase()){
            price = listing.price.toString();
        }
    }
   
    console.log('about to buy nft id: ', tokenId);
    console.log('about to buy nft at price: ', price);
    
    await runContract(process.env.REACT_APP_MARKETPLACE_CONTRACT, abiMarketplace, 'buyWithETH', [tokenId, contractAddress], 'TokenPurchased', (sender, tid) => {
        if(sender != window.wallet) return;
        toastSuccess('NFT bought successfully!');
    }, price);
    
}


function showError(e){

    console.log(e);

    var error = '';

    if(e.data && e.data.message){
        error = e.data.message;
    }

    if(e.error && e.error.data && e.error.data.message){
        error = e.error.data.message;
    }

    if(error == '' && e.error && e.error.message){
        error = e.error.message;
    }

    error = error.replace('execution reverted: ', '');
    
    if(error.includes('insufficient funds')){
        error = 'Insufficient funds';
    }

    if(error.includes('You have no rewards to claim')){
        error = 'You have no rewards to claim, please wait at least 24 hours';
    }

    if(error.includes('INVALID_OPERATOR')){
        error = 'Incubator not approved';
    }

    if(error == ''){
        toastError('please try again');
        return;
    }
    
    toastError(error);
    
}


function toastSuccess(msg){
    uniqueToast(msg, 'success');
}

function toastError(msg){
    uniqueToast(msg, 'error');
}

function uniqueToast(msg, type){
    var timeDiff = 0;
    if(window.timeLastToast){
        timeDiff = new Date().getTime() - window.timeLastToast;
    }
    console.log('time:' + timeDiff);
    if(window.lastToast == msg && timeDiff < 1000) return;
    window.lastToast = msg;
    window.timeLastToast = new Date().getTime();
    if(type == 'success'){
        toast.success(msg);
    }
    if(type == 'error'){
        toast.error(msg);
    }
    if(type == 'info'){
        toast.info(msg);
    }
}


export function formatAddress(address, length = 4) {
    return address.substring(2, 2 + length) + ' ... ' + address.substring(42 - length, 42);
}


export function describeTx(entry){

    if(entry.from == '0x0000000000000000000000000000000000000000'){
        return <div>
            <span className="text-green-300 font-bold">✨ Minted</span><br />
            to {formatAddress(entry.to)}
        </div>
    }

    if(entry.to.toLowerCase() == process.env.REACT_APP_MARKETPLACE_CONTRACT.toLowerCase()){
        return <div>
            from: {formatAddress(entry.from)}<br />
            to iykyk+ marketplace
        </div>;
    }

    return <div>
        from: {formatAddress(entry.from)}<br />
        to: {formatAddress(entry.to)}
    </div>
}